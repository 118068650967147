<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        class="d-flex justify-content-between flex-column"
      >
        <h4>Interview Details:</h4>
        <table class="w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="InfoIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Title</span>
            </th>
            <td class="pb-50">
              {{ userData.title }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 d-flex align-items-center">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Candidate</span>
            </th>
            <td class="pb-50">
              {{ userData.candidate_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Time</span>
            </th>
            <td class="pb-50">
              {{ userData.start_at }} - {{ userData.end_at }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status_label }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Agenda</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.agenta }}
            </td>
          </tr>
          <tr>
            <th class="d-flex align-items-center">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Interviewers</span>
            </th>
            <td>
              <span
                v-for="(cnt, index) in userData.interviewers_details"
                :key="index"
              >{{ cnt.name }},
              </span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BRow, BCol,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
